import { has } from "lodash";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { Result } from "../types/results-response-payload/results-payload";
import { StructuralBlock } from "../types/results-response-payload/structural-block";
import { Honourifics } from "./consts";
import { EnrichedAKBN, ResultMetadata, Structure } from "@openlaw-au/types";

const shouldSwapDsc = (a: number, b: number) => (a < b ? 1 : -1);
const shouldSwapAsc = (a: number, b: number) => (a > b ? 1 : -1);

/***
 * Evaluates whether an array is an Array and if it has
 * any elements.
 * @param array
 */
export const isArrayEmpty = (array: Array<any>) => !Array.isArray(array) || (array?.length && array.length < 1);

export const sortResults = (
    sortby: string,
    results: ResultMetadata<Structure>[],
    setSorted: (s: ResultMetadata<Structure>[]) => void
): void => {
    if (isArrayEmpty(results)) {
        setSorted([]);
    } else {
        setSorted(
            results.sort((rA: ResultMetadata<Structure>, rB: ResultMetadata<Structure>) => {
                if (rA.confidenceScore && rB.confidenceScore && rA.matched && rB.matched) {
                    let swap = 0;
                    switch (sortby) {
                        case "0":
                            swap = shouldSwapDsc(
                                Number.parseFloat(rA.confidenceScore),
                                Number.parseFloat(rB.confidenceScore)
                            );
                            break;
                        case "1":
                            // swap = shouldSwapDsc(rA.matched.citationMeta.inCites, rB.matched.citationMeta.inCites);
                            break;
                        case "2":
                            // swap = shouldSwapDsc(
                            //     rA.matched.citationMeta.pinpointCites,
                            //     rB.matched.citationMeta.pinpointCites
                            // );
                            break;
                        case "3":
                            swap = shouldSwapDsc(Date.parse(rA.matched.date), Date.parse(rB.matched.date));
                            break;
                        case "4":
                            swap = shouldSwapAsc(Date.parse(rA.matched.date), Date.parse(rB.matched.date));
                            break;
                        default:
                    }
                    return swap;
                } else {
                    return 0;
                }
            })
        );
    }
};

export const getAuthorsFromCoram = (idx: number, structure: EnrichedAKBN[], coram: string) => {
    const disallowedTerms = ["AND", "THE", "HONOURABLE"];
    const hasAuth = has(structure[idx], "opinionAuthor");
    const prevHasAuth = has(structure[idx - 1], "opinionAuthor");
    const authors: string = hasAuth ? structure[idx].opinionAuthor ?? "" : "";

    let hasChanged = !prevHasAuth;
    if (hasAuth && prevHasAuth) {
        hasChanged = structure[idx].opinionAuthor !== structure[idx - 1].opinionAuthor;
    }

    const presentationAuthors: string[] = coram.replace(new RegExp(",(?!s)"), ", ").split(" ");
    const sanitisedAuthors: string[] = authors!
        .split(" ")
        .filter(
            (frag: string) =>
                !disallowedTerms.includes(frag.toUpperCase()) &&
                !Honourifics.includes(frag.replace(/\W/g, "")) &&
                frag.trim().length > 0
        )
        .map((sa) => sa.toLowerCase());

    return { presentationAuthors, sanitisedAuthors, hasChanged };
};

export const generateJadeSearchUrl = (results: ResultMetadata<Structure>[]) => {
    if (isArrayEmpty(results)) {
        return "";
    }

    let searchUrl = "https://jade.io/search/order1.jaderank=asc:text=ids%253A+";
    results.forEach((result, idx) => {
        searchUrl += `${result.articleId}${idx !== results.length - 1 ? "," : ""}`;
    });

    return searchUrl;
};

export const useKeyPress = (callback: any, node = null) => {
    const callbackRef = useRef(callback);
    const [metaPressed, setMetaPressed] = useState(false);

    useLayoutEffect(() => {
        callbackRef.current = callback;
    });

    const handleKeyPress = useCallback(
        (e: any) => {
            if (e.key === "Meta") {
                setMetaPressed(true);
            } else if (e.key === "k" && metaPressed) {
                callback(false);
            } else if (e.key === "Escape") {
                callback(true);
            }
        },
        [callback, metaPressed]
    );

    const handleKeyUp = useCallback((e: any) => {
        if (e.key === "Meta") {
            setMetaPressed(false);
        }
    }, []);

    useEffect(() => {
        const targetNode = node ?? document;
        targetNode && targetNode.addEventListener("keydown", handleKeyPress);

        return () => targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
    }, [handleKeyPress, node]);

    useEffect(() => {
        const targetNode = node ?? document;
        targetNode && targetNode.addEventListener("keyup", handleKeyUp);

        return () => targetNode && targetNode.removeEventListener("keyup", handleKeyUp);
    }, [handleKeyUp, node]);
};

export const toTitleCase = (str: string) => {
    return str
        .replace(/\w\S*/g, (txt: string) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })
        .replace(/\s[V]\s/g, " v ");
};
