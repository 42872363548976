import React, { FunctionComponent, MouseEvent, useEffect, useState } from "react"
import { useRouter } from "next/router"

import { TextClass } from "../../util/consts"
import { MeilisearchDocument } from "@openlaw-au/types"
import SearchInfoBox from "./SearchInfoBox"

interface Props {
    searchOpt: number;
    handleSetSearchOption: (n: number) => void;
    setFocus: (f: boolean) => void;
    handleSearch: (e: string, n: number) => void;
    handleInputSearch: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
    suggestionData: MeilisearchDocument[];
}

const Suggester: FunctionComponent<Props> = ({
    searchOpt,
    handleSetSearchOption,
    setFocus,
    handleSearch,
    suggestionData,
    handleInputSearch,
}) => {
    const router = useRouter()

    const handleClickOnSuggestion = (event: MouseEvent<HTMLLIElement>, query: string, id: number) => {
        event.preventDefault()
        if (suggestionData.length > 0) {
            // window.open(`https://jade.io/article/${id}`, "_blank")
            router.push(`/summary/${id}`)
        } else {
            handleSearch(query, searchOpt)
        }
        setFocus(false)
    }

    const renderSuggesterHeader = () => {
        return (
            <li className='mt-4 md:mt-auto flex flex-row'>
                <div className='flex flex-row justify-between w-full'>
                    <h2 className={`${TextClass} mt-1 md:mt-0 font-semibold text-jade-primary`}>Search with</h2>
                    <button
                        type='button'
                        onClick={() => setFocus(false)}
                        className='visible md:invisible text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center'
                    >
                        <svg aria-hidden='true' className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20'>
                            <path
                                fillRule='evenodd'
                                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                                clipRule='evenodd'
                            ></path>
                        </svg>
                    </button>
                </div>
            </li>
        )
    }

    const renderPlaceholderSuggestions = (s: string[]) => {
        return s.map((s: string, idx: number) => (
            <li
                key={idx}
                id={s}
                className='font-sourceSansPro h-auto p-2 w-full pt-4 pb-4 md:py-2 hover:bg-gray-100 active:bg-gray-200 cursor-pointer'
                onMouseDown={(e) => e.preventDefault()}
                onClick={(e) => handleClickOnSuggestion(e, s, 0)}
            >
                {s}
            </li>
        ))
    }

    const renderQuicksearchSuggestions = (results: MeilisearchDocument[]) => {
        return results.map((doc: MeilisearchDocument, idx: number) => {
            return (
                <li
                    key={idx}
                    id={`${doc.id}`}
                    className='font-sourceSansPro text-jade-secondary h-auto p-2 w-full pt-4 pb-4 md:py-2 hover:bg-gray-100 active:bg-gray-200 cursor-pointer'
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => handleClickOnSuggestion(e, doc.title, Number(doc.id))}
                >
                    <span className='font-semibold'>{doc.title}</span> <span>{doc.mnc}</span>
                </li>
            )
        })
    }

    return (
        <ul className={"p-3"}>
            {renderSuggesterHeader()}
            {renderQuicksearchSuggestions(suggestionData)}
            <SearchInfoBox
                handleInputSearch={handleInputSearch}
                handleSetSearchOption={handleSetSearchOption}
                searchOption={searchOpt}
            />
        </ul>
    )
}

export default Suggester
